@import '../../../assets/scss/color-variables.scss';

.dropdown {
    position: relative;
    top: 0.3rem;
    left: 1rem;
    margin-left: -1rem !important;
    min-height: 32px;
    .dropdown-menu {
        position: absolute;
        top: 100%;
        right: -10px;
        left: auto;
        display: block;
        z-index: 3;
        padding: 5px !important;
        margin-top: 0.5rem;
        border: 0.0625rem solid $gray-light-color;
        -webkit-box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
        border-radius: 0;
        height: auto;
        // &:before {
        //     content: "";
        //     position: absolute;
        //     right: 0.625rem;
        //     top: -0.625rem;
        //     width: 0;
        //     height: 0;
        //     border-right: 0.5rem solid transparent;
        //     border-left: 0.5rem solid transparent;
        //     border-bottom: 0.625rem solid #bdbdbd;
        // }
      
           li {
            border-bottom: 0.5px solid $gray-color;
            margin: 0 !important;
            &:nth-child(1){
                padding-left: 10px !important;
            }
            &:nth-last-child(1){
                border-bottom: 0 !important;
            }
            &:hover{
                background-color: $gray-light-color;
                color: $black-color;
            }
            a {
                padding: 0.4rem 0.3rem !important;
                outline: 0;
                cursor: pointer;
                font-size: 12px !important;
                font-weight: 300 !important;
                color: $black-color;
                display: block;
                width: 100%;
                img {
                    margin-right: 0.5rem;
                    margin-top: -0.125rem;
                }
            
        }
           }
            
    }
}

.cursor{
    &:hover{
        text-decoration: none;
    }
}