$white-color: var(--white-color, #ffffff);
$black-color: var(--black-color, #2e2e38);
$gray-color: var(--gray-color, #bdbdbd);
$blue-color: var(--blue-color, #398ecd);
$link-color: var(--link-color, #1166C9);
$green-color: var(--green-color, #52aa2c);
$dark-green-color: var(--dark-green-color, #005900);
$red-color: var(--red-color, #FF5C3B);
$orange-color: var(--orange-color, #fc8525);
$container-color: var(--container-color, #f9f9f9);
$gray-light-color: var(--gray-light-color, #efeeee);
$black-overlay-bg: var(--black-overlay-bg, #2f2e2e9e);
$light-green-color: var(--light-green-color, #acf38d);
$navyblue-color: var(--navyblue-color, #0F0D30);
$light-orange-color: var(--light-orange-color, #fff4e6);
