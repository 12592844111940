

.labeltext{
    font-size: 12px;
    margin-bottom: 0;
    padding-left: 16px;
    padding-right: 5px;
}

.datetext{
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 6px;
    padding-left: 3px;
    font-style: italic;
}

.timeError{
    float: right;
    font-size: 10px;
    margin-bottom: 0;
    margin-top: 6px;
    padding-left: 3px;
    color: red;
}