* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import '../../assets/scss/main.scss';

html {
  font-size: 16px;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: var(--container-color, #f9f9f9);
  height: 100vh;
}
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000000b3;
  display: flex;
  align-items: center;
  z-index: 99988 !important;
}
.loader {
  border: none;
  color: #FFBF00;
  font-size: 20px;
  margin: 70px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,-3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,-3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,-2em -2em 0 0.2em;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


a {
  text-decoration: none;
}


.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
}
.ellipsis-icon {
  background-image: url("../images/3-dot-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 0.0625rem 0.6875rem;
  background-size: 1.0625rem;
}


.logout-icon {
  background-image: url("../images/logout\ \(1\).png");
  background-repeat: no-repeat;
  background-position: center;
  padding: 8px;
  background-size: 16px;
  opacity: 0.5;
}
.profile-icon{
  background-image: url("../images/user\ \(1\).png");
  background-repeat: no-repeat;
  background-position: center;
  padding: 8px;
  background-size: 16px;
  opacity: 0.5;
}

.menu-icon {
  background-image: url("../images/menu.png");
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}
.del-icon {
  display: block;
  background-image: url("../images/trash.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 12px;
  background-size: 1.0625rem;
  cursor: pointer;
}
.view-icon {
  padding: 10px;
  display: block;
  background-image: url("../images/view.png");
  background-repeat: no-repeat;
  background-position: center;
  padding: 0.0625rem 0.6875rem;
  background-size: 1.0625rem;
  cursor: pointer;
}
.request-icon {
  background-image: url("../images/request.png");
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}
.sequence-icon {
  background-image: url("../images/menu.svg");
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}
.cohort-icon {
  background-image: url("../images/team.svg");
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}
.students-icon {
  background-image: url("../images/user.svg");
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}

.calendar-icon {
  background-image: url("../images/calendar.svg");
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}

.home-icon {
  background-image: url("..//images/home.png");
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}

.setting-icon {
  background-image: url("../images/settings.png");
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}

.light-icons {
  filter: invert(1%) brightness(200) !important;
}
.dark-icons {
  filter: invert(73%) !important;
}
.home-icon-1 {
  background-image: url("../images/white-home.png");
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}

.add-icon {
  background-image: url("../images/plus.svg");
  background-repeat: no-repeat;
  padding: 11px;
  display: block;
  background-position: center;
  background-size: 11px;
}

.left-icon {
  background-image: url("../images/left-arrow.png");
  background-repeat: no-repeat;
  padding: 15px;
  display: block;
  background-position: center;
  background-size: 14px;
}

.login-main {
  height: 100vh;
  width: 100%;
}
.login-main .login-wrapper .login-info {
  width: 50rem;
  background-color: var(--white-color, #ffffff);
  -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
  padding: 2rem;
  border-radius: 1rem;
  position: relative;
  z-index: 999;
}
.login-main .login-wrapper .login-info .login-info-wrapper {
  padding-left: 20px;
}
.login-main .login-wrapper .login-info .login-info-wrapper .login-title {
  font-size: 1.5rem;
  color: var(--nevy-blue-color, #0f0d30);
  margin-bottom: 3rem;
  text-align: center;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.login-main .login-wrapper .login-info .login-info-wrapper .forgot-link {
  font-size: 0.625rem;
  color: var(--blue-color, #298fff);
  text-decoration: underline;
  margin-top: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 600;
}
.login-main .login-wrapper .login-info .login-info-wrapper .inputgroup {
  margin-bottom: 1rem;
}
.login-main .login-wrapper .login-info .login-info-wrapper .inputgroup .label {
  margin-left: 10px;
}
.login-main .login-wrapper .login-info .login-info-wrapper .link-box {
  border-top: 1px solid var(--gray-color, #bdbdbd);
  margin-top: 3rem;
  padding-bottom: 1rem;
  text-align: center;
}
.login-main .login-wrapper .login-info .login-info-wrapper .link-box .link-title {
  font-size: 0.75rem;
  color: var(--blue-color, #298fff);
  text-decoration: none !important;
  cursor: pointer;
}
.login-main .login-wrapper .login-info .login-info-wrapper .login-btn {
  margin-top: 2rem;
}
.login-main .login-wrapper .login-info .login-info-wrapper .login-btn .btn-primary {
  padding: 0.5rem 4rem;
}
.login-main .login-wrapper .login-info .login-logo-wrapper {
  text-align: center;
  border-right: 1px solid var(--gray-color, #bdbdbd);
  padding-right: 20px;
}
.login-main .login-wrapper .login-info .login-logo-wrapper .logo {
  background-image: url("../images/ProjectYou-logo-1.png");
  background-repeat: no-repeat;
  padding: 4.375rem;
  display: block;
  background-position: center;
  background-size: 9rem;
  text-decoration: none;
  margin-bottom: 5px;
}
.login-main .login-wrapper .login-info .login-logo-wrapper .logo-title {
  font-size: 20px;
  color: var(--black-color, #2e2e38);
  display: block;
  text-decoration: none;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.login-main .login-wrapper .login-info .login-logo-wrapper .tag-line-box {
  border-top: 1px solid var(--gray-color, #bdbdbd);
  margin: 24px 4rem;
  padding: 12px;
}
.login-main .login-wrapper .login-info .login-logo-wrapper .tag-line-box .tag-line {
  font-size: 12px;
  color: var(--black-color, #2e2e38);
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;

  background-color: var(--white-color, #ffffff);
  -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
}

header .navbar {
  padding: 1rem 0;
}
header .navbar .logo-wrapper ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
header .navbar .logo-wrapper ul li {
  padding: 0 12px;
}
header .navbar .logo-wrapper ul li a {
  font-size: 1.3rem;
  color: var(--black-color, #2e2e38);
  font-weight: 600;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
header .navbar .logo-wrapper ul li a.logo {
  background-image: url("../images/ProjectYou-logo-2.png");
  background-repeat: no-repeat;
  padding: 18px 75px;
  display: block;
  background-position: center;
  background-size: contain;
}
header .navbar .logo-wrapper ul li:nth-child(1) {
  padding-left: 0;
}
header .navbar .menu-wrapper ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
header .navbar .menu-wrapper ul li {
  padding: 0 12px;
  margin-left: 1rem;
}
header .navbar .menu-wrapper ul li a {
  font-size: 1.3rem;
  color: var(--black-color, #2e2e38);
  font-weight: 600;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
/* header .navbar .menu-wrapper ul li:nth-child(1) {
  padding-left: 0;
} */
header .navbar .menu-wrapper ul li .profile-info .profile-text {
  margin-right: 5px;
  text-align: right;
}
header .navbar .menu-wrapper ul li .profile-info .profile-text .welcome-text {
  margin-bottom: -2px;
  font-size: 10px;
  color: var(--orange-color, #ff5c3b);
  display: block;
  font-family: "Poppins", sans-serif;
}
header .navbar .menu-wrapper ul li .profile-info .profile-text .user-title {
  font-size: 14px;
  color: var(--black-color, #2e2e38);
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
header .navbar .menu-wrapper ul li .profile-info .user-profile {
  height: 2.1875rem;
  width: 2.1875rem;
  border-radius: 0.3125rem;
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  background-color: var(--white-color, #ffffff);
}
header .navbar .menu-wrapper ul li .profile-info .user-profile img {
  height: 100%;
  width: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--white-color, #ffffff);
  -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
}
.footer .footer-wrapper {
  padding: 8px;
  text-align: center;
}
.footer .footer-wrapper a {
  font-size: 11px;
  color: var(--black-color, #2e2e38);
}

body .btn {
  font-size: 14px;
  color: var(--black-color, #2e2e38);
  padding: 0.5rem 1.25rem;
  border-radius: 5px;
  border: 1px solid var(--gray-color, #bdbdbd);
  font-weight: 600;
  display: inline-block;
  transition: all 0.3s ease-in;
  cursor: pointer;
}
body .btn:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--orange-color, #ff5c3b);
  border-color: var(--orange-color, #ff5c3b);
  color: var(--white-color, #ffffff);
}
body .btn:hover {
  background-color: var(--nevy-blue-color, #0f0d30);
  color: var(--white-color, #ffffff);
  border-color: var(--nevy-blue-color, #0f0d30);
  transition: all 0.3s ease-in;
}

body .btn-primary {
  background-color: var(--orange-color, #ff5c3b);
  border-color: var(--orange-color, #ff5c3b);
  color: var(--white-color, #ffffff);
}
.link-title {
  font-size: 0.75rem;
  color: var(--blue-color, #298fff);
  text-decoration: none !important;
  cursor: pointer;
}


.main-container {
  padding-top: 88px;
  padding-bottom: 60px;
}
.main-container .box-wrapper {
  padding: 1rem;
  background-color: var(--white-color, #ffffff);
  border-radius: 0.3125rem;
  position: relative;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
}
.main-container .sub-title {
  font-size: 20px;
  padding: 0.25rem 0;
  font-weight: 700;
  margin-bottom: 0;
  color: var(--black-color, #2e2e38);
}
.main-container .add-btn {
  display: flex;
  align-items: center;
}
.main-container .add-btn a {
  font-size: 12px;
  color: var(--black-color, #2e2e38);
  cursor: pointer;
  margin-right: 5px;
  font-weight: 600;
}
.main-container .add-btn .add-box {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #FFBF00;
  -webkit-box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
  margin-right: 0;
}
.main-container .add-btn .add-box:hover {
  background-color: lightgrey;
  color: var(--white-color, #ffffff);
  transition: all 0.3s ease-in-out;
}

.left-menu-panel {
  width: 250px;
  min-height: calc(100vh - 150px);
  padding: 1rem 0rem 1rem 1rem;
  background-color: var(--nevy-blue-color, #0f0d30);
  border-radius: 0 0.3125rem 0.3125rem 0;
  position: relative;
  -webkit-box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.left-menu-panel .collpase-arrow-box {
  background-color: var(--white-color, #ffffff);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  margin-right: 1rem;
  cursor: pointer;
}
.left-menu-panel .collpase-arrow-box .right-icon {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}
.left-menu-panel .collpase-arrow-box:hover {
  background-color: var(--orange-color, #ff5c3b);
  color: var(--white-color, #ffffff);
  transition: all 0.3s ease-in-out;
}
.left-menu-panel .collpase-arrow-box:hover .left-icon {
  background-image: url("../images/left-arrow-white.png");
}
.left-menu-panel .nav-info .nav .nav-item .nav-link {
  font-size: 1rem;
  color: var(--white-color, #ffffff);
  font-weight: 500;
  border-radius: 5rem 0 0 5rem;
  margin: 5px 0;
  transition: all 0.3s ease-in-out;
}
.left-menu-panel .nav-info .nav .nav-item .nav-link span {
  margin-right: 1rem;
}
.left-menu-panel .nav-info .nav .nav-item .nav-link.active {
  background-color: var(--white-color, #ffffff);
  color: var(--black-color, #2e2e38);
  transition: all 0.3s ease-in-out;
}
.left-menu-panel .nav-info .nav .nav-item .nav-link.active:hover {
  background-color: var(--white-color, #ffffff);
  color: var(--black-color, #2e2e38);
  transition: all 0.3s ease-in-out;
}
.left-menu-panel .nav-info .nav .nav-item .nav-link:hover {
  background-color: var(--orange-color, #ff5c3b);
  color: var(--white-color, #ffffff);
  transition: all 0.3s ease-in-out;
}

.right-container-panel {
  width: 100%;
}

.inputgroup .form-control {
  border: 0.0625rem solid var(--border-color, #e6e6e6);
  background-color: var(--white-color, #ffffff);
  width: 100%;
  box-shadow: none;
  outline: none;
  font-size: 0.8125rem;
  padding: 0.4375rem 0.625rem;
  color: var(--black-color, #2e2e38);
  font-weight: 400;
  border-radius: 5rem;
  height: auto;
  transition: all 0.3s ease-in-out;
  border-radius: 2px;
}
.inputgroup .form-control::placeholder {
  font-size: 0.8125rem;
  color: var(--black-color, #2e2e38);
  font-weight: 400;
  opacity: 0.5;
}
.inputgroup .form-control:hover {
  border-color:#298FFF !important;
  transition: all 0.3s ease-in-out;
}
.inputgroup .form-control:focus {
  box-shadow: none !important;
  border-color: #298FFF
}
.inputgroup label {
  font-size: 0.875rem;
  color: var(--black-color, #2e2e38);
  margin-bottom: 0.4375rem;
  font-weight: 400;
}

.tableformatCls .table thead tr th {
  font-size: 14px;
  color: var(--black-color, #2e2e38);
  font-weight: 700;
  padding: 0.625rem;
}
.tableformatCls .table tbody tr td {
  font-size: 14px;
  color: var(--black-color, #2e2e38);
  font-weight: 500;
  padding: 0.625rem;
  transition: all 0.3s ease-in-out;
}
.tableformatCls .table tbody tr td.action-title {
  color: var(--blue-color, #298fff);
  font-weight: 600;
  cursor: pointer;
}


.separator {
  display: block;
  width: 100%;
  height: 0.0625rem;
  border-bottom: 0.0625rem solid var(--gray-color, #bdbdbd);
  margin-bottom: 1rem;
  margin-top: 10px;
}

.calendar-info-title {
  font-size: 1rem;
  color: var(--black-color, #2e2e38);
  margin-top: 2rem;
}

.calendar-list {
  margin-top: 1rem;
}
.calendar-list .nav {
  list-style-type: inherit;
  margin-left: 2rem;
}
.calendar-list .nav .nav-item .nav-link {
  background-color: transparent;
  color: var(--blue-color, #298fff);
  border: 0;
  padding: 7px 1rem;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  padding-left: 5px;
  font-weight: 600;
}
.calendar-list .nav .nav-item .nav-link:hover {
  color: var(--blue-color, #298fff);
  text-decoration: underline;
}

.action-modal.modal .modal-dialog .modal-content {
  border-radius: 10px;
}
.action-modal.modal .modal-dialog .modal-content .modal-header {
  padding: 10px;
  display: none;
}
.action-modal.modal .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 18px;
  color: var(--black-color, #2e2e38);
}
.action-modal.modal .modal-dialog .modal-content .modal-header .btn-close {
  font-size: 12px;
  margin-right: 0;
}
.action-modal.modal .modal-dialog .modal-content .modal-body {
  padding: 1rem 3rem;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 0;
}
.action-modal.modal .modal-dialog .modal-content .modal-body .action-title {
  font-size: 14px;
  color: var(--black-color, #2e2e38);
  font-weight: 600;
}
.action-modal.modal .modal-dialog .modal-content .modal-footer {
  justify-content: center;
  border: 0;
  margin-bottom: 3rem;
}
.action-modal.modal .modal-dialog .modal-content .modal-footer .btn {
  padding: 0.5rem 2rem;
}

.google-btn {
  display: flex;
  align-items: center;
  color: #2e2e38;
  /* box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16); */
  padding: 12px;
  border-radius: 0;
  border: 1px solid #848484;
  font-size: 14px;
  font-weight: 500;
  width: 7rem;
  font-family: "Open Sans", sans-serif !important;
  width: 184px;
  height: 42px;
  cursor: pointer;
  background-color: white;
}
.google-btn a:hover{
    color: #4e4d4d;
    text-decoration: none;

}

/* .google-btn {
 
} */
.google-icon-wrapper {
  margin-right: 12px;
  border-radius: 5rem;
  background-color: #fff;
}
.google-icon {
  width: 19px;
  height: 21px;
}
.btn-text {
  color: #4e4d4d;
  font-size: 14px;
  letter-spacing: 0.2px;
  text-decoration: none;
  font-weight: 600;
}
.edit-icon {
  background-image: url("../images/edit.svg");
  background-repeat: no-repeat;
  padding: 12px;
  display: block;
  background-position: center;
  background-size: 0.875rem;
  cursor: pointer;
}

.copy-icon {
  background-image: url("../images/document.svg");
  background-repeat: no-repeat;
  padding: 0.875rem;
  display: block;
  cursor: pointer;
  background-position: center;
  background-size: 1.675rem;
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  z-index: 5;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 40%;
  margin: 0 auto;
  height: 25%;
  max-height: 70vh;
  margin-top: 10rem;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
.sm-box {
  position: relative;
  width: 35%;
  margin: 0 auto;
  height: 31%;
  max-height: 70vh;
  margin-top: 9rem;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
.lr-box{
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: 160%;
  max-height: 75vh;
  margin-top: 2rem;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
/* .inner-box {
  padding: 14px;
  border: 1px solid lightgrey;
  overflow: auto;green-bg
} */
.sm-close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(32% - 2px);
  top: 8.5rem;
  background: #ff5c3b;
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 15px;
  text-align: center;
  border: 2px solid white;
  font-size: 20px;
}
.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(30% - 9px);
  top: 9rem;
  background: #ff5c3b;
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 15px;
  text-align: center;
  border: 2px solid white;
  font-size: 20px;
}

/* .green-bg {
  background-color: #4dff0070;
  border: 1px solid white;
} */

.text-center {
  text-align: center;
}

.w-5 {
  width: 5rem;
}
/* .react-datepicker {
  width: 33rem !important;
} */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.create-text {
  font-size: 13px;
  margin: 5px;
  font-weight: 600;
}
.circle-box {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  color: white;
  background-color: #ffd53b;
  margin-right: 0;
  text-align: center;
}
.cursor {
  cursor: pointer;
}
/* .react-confirm-alert-overlay {
  background: #242424d9 !important;
} */
.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background:#FFBF00;;
  color: #fff;
}
.confirm-btn {
  padding: 10px;
  margin: 10px;
  background: transparent;
  font-size: 14px;
  border: 0.0625rem solid white;
  color: white;
  position: relative;
  z-index: 3;
  overflow: hidden;
  outline: none;
  
}
.confirm-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  color: white;
  z-index: -1;
  transform: translatex(-100%);
  transition: all 0.4s ease-in-out;
}
.confirm-btn:hover {
  color: black;
}
.confirm-btn:hover:before {
  width: 100%;
  transform: translatex(0);
  transition: all 0.4s ease-in-out;
}
.confirm-btn:focus {
  outline: none;
  box-shadow: none;
}
.red-btn {
 line-height: 1 !important;
 border: none !important;
 background: red !important;
}

.green-btn {
  line-height: 1 !important;
  border: none !important;
  background: green !important;
 }
 .green-text{
   color: green !important;
 }
 .red-text{
  color: red !important;
 }
 .normal-text{
  color: #0f0d30 !important;
 }
 .disabled-btn{
  background: lightgrey !important;
  line-height: 1 !important;
  border: none !important;
 }
/*# sourceMappingURL=main.css.map */
