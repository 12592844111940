@import 'color-variables.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body {
    font-family: 'Open Sans', sans-serif;
    background-color: $container-color;
    height: 100vh;
}
a{
    text-decoration: none;
}
.container-fluid{
    padding-right: 20px;
    padding-left: 20px;
}

.menu-icon{
  background-image: url('../images/menu.png');
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}

.home-icon{
  background-image: url('..//images/home.png');
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}

.setting-icon{
  background-image: url('../images/settings.png');
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}
.home-icon-1{
  background-image: url('../images/white-home.png');
  background-repeat: no-repeat;
  padding: 10px;
  display: block;
  background-position: center;
  background-size: 18px;
}
.add-icon{
  background-image: url('../images/plus-white.png');
  background-repeat: no-repeat;
  padding: 12px;
  display: block;
  background-position: center;
  background-size: 10px;
}
.left-icon{
  background-image: url('../images/left-arrow.png');
  background-repeat: no-repeat;
  padding: 15px;
  display: block;
  background-position: center;
  background-size: 14px;
}
.edit-icon {
    background-image: url("../images/draw.png");
    background-repeat: no-repeat;
    padding: 12px;
    display: block;
    background-position: center;
    background-size: 15px;
    cursor: pointer;
  }
  .delete-icon {
    background-image: url("../images/delete.png");
    background-repeat: no-repeat;
    padding: 12px;
    display: block;
    background-position: center;
    background-size: 15px;
    cursor: pointer;
  }
  .ellipsis-icon {
    background-image: url("../images/3-dot-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    padding: 0.0625rem 0.6875rem;
    background-size: 1.0625rem;
  }
  .view-icon {
    padding: 10px;
    display: block;
    background-image: url("../images/view.png");
    background-repeat: no-repeat;
    background-position: center;
    padding: 0.0625rem 0.6875rem;
    background-size: 1.0625rem;
    cursor: pointer;
  }
  .request-icon {
    background-image: url("../images/request.png");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 18px;
  }
  .sequence-icon {
    background-image: url("../images/menu.svg");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 18px;
  }
  .cohort-icon {
    background-image: url("../images/user-white.png");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 18px;
  }
  .students-icon {
    background-image: url("../images/user.svg");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 18px;
  }
  
  .calendar-icon {
    background-image: url("../images/calendar.svg");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 18px;
  }
  
  .home-icon {
    background-image: url("..//images/home.png");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 18px;
  }
  
  .setting-icon {
    background-image: url("../images/settings.png");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 18px;
  }
  .copy-icon{
    background-image: url("../images/files-copy-interface-symbol.png");
    background-repeat: no-repeat;
    padding: 16px;
    display: block;
    background-position: center;
    background-size: 18px;
  }
  .back-icon{
    background-image: url("../images/previous.png");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 10px;
  }
  .support-icon{
    background-image: url("../images/support.png");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 20px;
  }
  .fb-icon{
    background-image: url("../images/facebook.png");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 20px;
  }
  .insta-icon{
    background-image: url("../images/instagram.png");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 20px;
  }
  .whatsapp-icon{
    background-image: url("../images/whatsapp.png");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 20px;
    
  }
  .timeout-icon{
    background-image: url("../images/401-error-icon.png");
    background-repeat: no-repeat;
    padding: 10px;
    display: block;
    background-position: center;
    background-size: 1rem;
    filter: invert(50%) sepia(26%) saturate(2403%) hue-rotate(
        331deg
        ) brightness(99%) contrast(105%);
  }
  .insta-icon{
    background-image: url("../images/instagram\ \(1\).png");
    background-repeat: no-repeat;
    padding: 16px;
    display: block;
    background-position: center;
    background-size: cover;
  }
  .linkdin-icon{
    background-image: url("../images/linkedin.png");
    background-repeat: no-repeat;
    padding: 16px;
    display: block;
    background-position: center;
    background-size: cover;
  }
  .support-logo{
    background-image: url("../images/support-logo.png");
    background-repeat: no-repeat;
    padding: 80px;
    display: block;
    background-position: center;
    background-size: 220px;
  }

  .light-icons {
    filter: invert(1%) brightness(200) !important;
  }
  .dark-icons {
    filter: invert(73%) !important;
  }
  .separator {
    display: block;
    width: 100%;
    height: 0.0625rem;
    border-bottom: 0.0625rem solid $gray-color;
    margin-bottom: 1rem;
    margin-top: 10px;
  }
  .orange-separator {
    display: block;
    width: 100%;
    height: 0.0625rem;
    border-bottom: 0.0625rem solid $orange-color;
    margin-bottom: 1rem;
    margin-top: 10px;
  }
  .b-right{
    display: block;
    width: 0.0625rem;
    height: 100%;
    border-right: 0.0625rem solid $gray-color;
  }
  .action-title{
    color: $link-color;
    font-weight: 600;
    cursor: pointer;
    &:hover{
        text-decoration: none;
    }
  }

.login-main{
    height: 100vh;
    width: 100%;
    .login-wrapper{
        .login-info{
             width: 50rem;
             background-color: $white-color;
             -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
                box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
                padding: 2rem;
                border-radius: 1rem;
                position: relative;
                z-index: 9999;
                .login-info-wrapper{
                    padding-left: 20px;
                    .login-title{
                        font-size: 1.5rem;
                        color: $black-color;
                        margin-bottom: 3rem;
                        text-align: center;
                        font-weight: 600;
                        font-family: 'Raleway', sans-serif;
                    }
                    .forgot-link{
                        font-size: 0.625rem;
                        color: $link-color;
                        text-decoration: underline;
                        margin-top: 5px;
                        cursor: pointer;
                        margin-right: 10px;
                        font-weight: 600;
                    }
                    .inputgroup{
                       margin-bottom: 1rem;
                       .label{
                           font-size: 12px;
                           margin-left: 3px;
                       }
                    }
                    .link-box{
                        border-top: 1px solid $gray-color;
                        margin-top: 3rem;
                        padding-bottom: 1rem;
                        text-align: center;
                       .link-title{
                           font-size: 0.75rem;
                           color: $link-color;
                           text-decoration: none;
                           cursor: pointer;
                        }
                    } 
                    .login-btn{
                        margin-top: 2rem;
                       .btn-primary{
                           padding: 0.5rem 3rem;
                       }
                    }
                }
            .login-logo-wrapper{
                text-align: center;
                border-right: 1px solid $gray-color;
                padding-right: 20px;
                .logo{
                    font-size: 4rem;
                    color: $green-color;
                    display: block;
                    text-decoration: none;
                    font-weight: 700; 
                }
                .logo-title{
                    font-size: 20px;
                    color: $black-color;
                    display: block;
                    text-decoration: none;
                    font-weight: 600;
                    font-family: 'Raleway', sans-serif;
                }
                .tag-line-box{
                    border-top: 1px solid $gray-color;
                    margin: 24px 3rem;
                    padding: 12px;
                    .tag-line{
                        font-size: 12px;
                        color: $black-color;
                    }
                }
            }
        }
        .google-btn {
            display: flex;
            align-items: center;
            color: $black-color;
            /* box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16); */
            padding: 10px 12px;
            border-radius: 0;
            border: 1px solid #848484;
            font-size: 14px;
            font-weight: 500;
            width: 110px;
            font-family: "Open Sans", sans-serif !important;
            height: 42px;
            cursor: pointer;
            background-color: $white-color;
            .btn-text {
                color: $black-color;
                font-size: 14px;
                letter-spacing: 0.2px;
                text-decoration: none;
                font-weight: 400;
                &:hover{
                    color: $black-color;
                    text-decoration: none;
                
                }
              }
          }
          
    }
}

header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background-color: $white-color;
    -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
        box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
    .navbar{
        padding: 1rem 0;
        .logo-wrapper{
            ul{
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 0;
                li{
                    padding: 0 12px;
                    a{
                        font-size: 1.3rem;
                        color: $black-color;
                        font-weight: 600;
                        cursor: pointer;
                        font-family: 'Raleway', sans-serif;
                    }
                    &:nth-child(1){
                        padding-left: 0;
                    }
                }
            }
        }
        .menu-wrapper{
            ul{
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 0;
                li{
                    padding: 0 12px;
                    margin-left: 1rem;
                    a{
                        font-size: 1.3rem;
                        color: $black-color;
                        font-weight: 600;
                        cursor: pointer;
                        font-family: 'Raleway', sans-serif;
                    }
                    &:nth-child(1){
                        padding-left: 0;
                    }
                    .profile-info{
                        .profile-text{
                            margin-right: 5px;
                            text-align: right;
                            cursor: pointer;
                            .welcome-text{
                                font-size: 10px;
                                color: $orange-color;
                                display: block;
                                font-family: 'Raleway', sans-serif;
                                cursor: pointer;
                            }
                            .user-title{
                                font-size: 14px;
                                color: $black-color;
                                display: block;
                                font-weight: 600;
                                font-family: 'Raleway', sans-serif;
                                cursor: pointer;
                            }
                        } 
                        .user-profile{
                            height: 2.1875rem;
                            width: 2.1875rem;
                            border-radius: 0.3125rem;
                            box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.16);
                            overflow: hidden;
                            background-color: $white-color;
                            img{
                                height: 100%;
                                width: 100%;
                            }
                        }   
                    }
                }
            }
        }
    }
}
.footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white-color;
    -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.15);
    .footer-wrapper{
        padding: 8px;
        text-align: center;
        a{
            font-size: 11px;
            color: $black-color;
        }
    }
}

.main-container{
    padding-top: 88px;
    padding-bottom: 60px;
    .box-wrapper{
        padding: 1rem;
        background-color: $white-color;
        border-radius: 0.3125rem;
        position: relative;
        -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    }
    .sub-title{
        font-size: 20px;
        padding: 0.25rem 0;
        font-weight: 700;
        margin-bottom: 0;
        color: $navyblue-color;
    }
    .table-title{
        font-size: 17px;
        padding: 0.25rem 0;
        font-weight: 700;
        margin-bottom: 0;
        color: $navyblue-color;
    }
    .link-title{
        font-size: 12px;
        color: $link-color;
        font-weight: 600;
    }
    .label{
        font-size: 0.875rem;
        color: $black-color;
        margin-bottom: 0.4375rem;
        font-weight: 400;
        display: block;
      }
    .add-btn{
        display: flex;
        align-items: center;
        a{
            font-size: 12px;
            color: $black-color;
            cursor: pointer;
            margin-right: 5px;
            font-weight: 600;;
        }
        .add-box{
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background-color: $navyblue-color;
            -webkit-box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
            margin-right: 0;
            &:hover{
                background-color: $red-color;
            }
        }
    }
    .back-btn{
        display: flex;
        align-items: flex-end;
        font-size: 14px;
        color: $black-color;
        font-weight: 600;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover{
            text-decoration: none;
            color: $red-color;
            transition: all 0.3s ease-in-out;
            .back-icon{
                filter: invert(41%) sepia(77%) saturate(825%) hue-rotate(330deg) brightness(100%) contrast(102%);
                transition: all 0.3s ease-in-out;
            }
        }
    }
}
.left-menu-panel{
    width: 300px;
    min-height: calc(100vh - 150px);
    padding: 1rem 0rem 1rem 1rem;
    background-color: $navyblue-color;
    border-radius: 0 0.3125rem 0.3125rem 0;
    position: relative;
    -webkit-box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
    .collpase-arrow-box{
        background-color: $white-color;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        -webkit-box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 0.125rem 0.1875rem 0 rgba(0, 0, 0, 0.16);
          margin-bottom: 2rem;
          margin-right: 1rem;
          cursor: pointer;
          .right-icon{
              transform: rotate(180deg);
              transition: all 0.3s ease-in-out;
          }
    }
    .nav-info{
        .nav{
            .nav-item{
                .nav-link{
                    font-size: 1rem;
                    color: $white-color;
                    font-weight: 600;
                    border-radius: 5rem 0 0 5rem;
                    margin: 5px 0;
                    transition: all 0.3s ease-in-out;
                    span{
                        margin-right: 1rem;
                    }
                    &.active{
                        background-color: $white-color;
                        color: $black-color;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}
.right-container-panel{
    width: 100%;
}

.inputgroup{
    margin-bottom: 1rem;
    .form-control{
      border: 0.0625rem solid $gray-color;
      background-color: $white-color;
      width: 100%;
      box-shadow: none;
      outline: none;
      font-size: 0.8125rem;
      padding: 9px;
      color: $black-color;
      font-weight: 400;
      border-radius: 5px;
      height: auto;
      transition: all 0.3s ease-in-out;
      &::placeholder {
        font-size: 0.8125rem;
        color: $black-color;
        font-weight: 400;
        opacity: 0.5;
      }
    &:hover {
      border-color: $blue-color!important;
      transition: all 0.3s ease-in-out;
    }
    &:focus{
      box-shadow: none !important;
      border-color: $blue-color;
    }
    &.disabled {
        color: $black-color;
        background-color: $gray-light-color;
        border-color: $gray-light-color;
        &:hover{
            background-color: $gray-light-color;
            color: $black-color;
            border-color: $gray-light-color !important;
            transition: all 0.3s ease-in;
          }
      }
      &:disabled {
        color: $black-color;
        background-color: $gray-light-color;
        border-color: $gray-light-color;
        &:hover{
            background-color: $gray-light-color;
            color: $black-color;
            border-color: $gray-light-color !important;
            transition: all 0.3s ease-in;
          }
      }
    &.info-form{
        background-color: $gray-light-color;
        color: $black-color;
        border-color: $gray-light-color;
        &:hover{
            border-color: $gray-light-color !important;
        }
    }
    &.text-contol{
        resize: none;
    }
    }
    .upload-hide{
        display: none;
    }
    label{
      font-size: 0.875rem;
      color: $black-color;
      margin-bottom: 0.4375rem;
      font-weight: 400;
    }
  }

  .btn{
    font-size: 0.8125rem;
    color: $black-color;
    padding: 0.5rem 1.25rem;
    border-radius: 5px;
    border: 1px solid $gray-color;
    font-weight: 400;
    display: inline-block;
    background-color: $gray-color;
    transition: all 0.3s ease-in;
    cursor: pointer;
      &:focus{
        outline: none;
        box-shadow: none;
        background-color: $green-color;
        border-color: $green-color;
        color: $white-color;
      }
      &:hover{
        background-color: $navyblue-color;
        color: $white-color;
        border-color: $navyblue-color;
        transition: all 0.3s ease-in;
      }
      &.disabled {
        color: $white-color;
        background-color: $red-color;
        border-color: $red-color;
        opacity: 0.5;
        &:hover{
            background-color: $red-color;
            color: $white-color;
            border-color: $red-color;
            transition: all 0.3s ease-in;
          }
      }
      &:disabled {
        color: $white-color;
        background-color: $red-color;
        border-color: $red-color;
        opacity: 0.5;
        &:hover{
            background-color: $red-color;
            color: $white-color;
            border-color: $red-color;
            transition: all 0.3s ease-in;
          }
      }  
  }
  .btn-primary{
    background-color: $red-color;
    border-color: $red-color;
    color: $white-color;
    &:hover{
        background-color: $navyblue-color;
        color: $white-color;
        border-color: $navyblue-color;
        transition: all 0.3s ease-in;
      }
  }

  .tableformatCls{
    max-height: calc(100vh - 250px);
    overflow: auto;
      .table{
          thead{
              tr{
                  th{
                      font-size: 14px;
                      color: $black-color;
                      font-weight: 700;
                      padding: 0.625rem;
                      border-top: 0;
                      border-bottom: 1px solid $gray-color;
                  }
              }
          }
          tbody{
                tr{
                    td{
                        font-size: 14px;
                        color: $black-color;
                        font-weight: 500;
                        padding: 0.625rem;
                        transition: all 0.3s ease-in-out;
                        border-bottom: 1px solid $gray-color;
                        border-top: 0;
                        vertical-align: middle;
                        &.action-title{
                            color: $link-color;
                            font-weight: 600;
                            cursor: pointer;
                          }
                    }
                    .action-icon{
                        list-style-type: none;
                        margin-bottom: 0;
                        li{
                            padding: 0 5px;
                            a{
                                background-color: $gray-light-color;
                                 border-radius: 5px;
                                 padding: 14px;
                              }
                        }
                    }
                    // &:hover{
                    //     background-color: $container-color;
                    //     transition: all 0.3s ease-in-out;
                    // }
                }   
          }
      }
      &::-webkit-scrollbar {
        width: 0.9375rem;
        border: none;
        background: transparent;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-track-piece,
    &::-webkit-scrollbar-corner,
    &::-webkit-resizer {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.375rem;
        background-color: $navyblue-color;
        border-left: 0.3125rem solid $white-color;
        border-right: 0.3125rem solid $white-color;
        border-top: 0.3125rem solid $white-color;
        border-bottom: 0.3125rem solid $white-color;
    }

    &::-webkit-scrollbar-track {
        background-color: $white-color;
        border: 0.375rem solid $white-color;
        background-clip: padding-box;
    }
  }
  

//   .Advisor-box{
//     min-height: calc(100vh - 150px);
//   }
  .calendar-info-title{
      font-size: 1rem;
      color: $black-color;
      margin-top: 2rem;
  }
  .calendar-list{
      margin-top: 1rem;
      .nav{
          list-style-type: inherit;
          margin-left: 2rem;
          .nav-item{
              .nav-link{
                   background-color: transparent;
                   color: $link-color;
                   border: 0;
                   padding: 7px 1rem;
                   margin-bottom: 5px; 
                   display: block;
                   font-size: 14px;
                   padding-left: 5px;
                   font-weight: 600;
                   &:hover{
                       color: $link-color;
                       text-decoration: underline;
                   }
              }
          }
      }
  }
  .action-modal{
    &.modal{
        .modal-dialog{
            .modal-content{
                border-radius: 10px;
                .modal-header{
                    padding: 10px;
                    display: none;
                    .modal-title{   
                        font-size: 18px;
                        color: $black-color;
                    }
                    .btn-close{
                        font-size: 12px;
                        margin-right: 0;
                    }
                }
                .modal-body{
                    padding: 1rem 3rem;
                    text-align: center;
                    padding-top: 3rem;
                    padding-bottom: 0;
                    .action-title{
                        font-size: 14px;
                        color: $black-color;
                        font-weight: 600;
                    }
                }
                .modal-footer{
                    justify-content: center;
                    border: 0;
                    margin-bottom: 3rem;
                    .btn{
                        padding: 0.5rem 2rem;
                    }
                }
            }
        }
    }
  }

.react-confirm-alert-overlay{
        background-color: $black-overlay-bg !important;
    }
  .conformation-popup{
    width: 500px;
    background-color: $white-color;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    h1{
        font-size: 20px;
        color: $black-color;
        font-weight: 700;
    }
    p{
      font-size: 16px;
      color: $black-color;
    }
    .confirm-btn{
        background-color: $red-color;
        color: $white-color;
        font-size: 14px;
        font-weight: 600;
        border-radius: 5px;
        padding: 10px;
        border: 1px solid $white-color;
        outline: none;
        transition: all 0.3s ease-in;
        width: 9rem;
        &:hover{
            background-color: $black-color;
            color: $white-color;
            transition: all 0.3s ease-in;
        }
        &::before{
            display: none;
        }
    }
}

.tabFormatCls{
    .tab-header{
        .btn-group{
            .btn{
                font-size: 14px;
                color: $black-color;
                padding: 0.5rem 1.25rem;
                border-radius: 5px;
                border: 1px solid $gray-light-color;
                font-weight: 600;
                display: inline-block;
                transition: all 0.3s ease-in;
                cursor: pointer;
                background-color: transparent;
                min-width: 150px;
                margin-right: 10px;
                -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
                 box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
                 &:hover{
                     background-color: $navyblue-color;
                     color: $white-color;
                     transition: all 0.3s ease-in;
                 }
                &.active-btn{
                    background-color: $red-color;
                    color: $white-color;
                    transition: all 0.3s ease-in;
                }
            }
        }
    }
    .tab-panel{
        padding: 1rem;
        background-color: $white-color;
        border-radius: 0.3125rem;
        position: relative;
        -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
        border: 1px solid $gray-light-color;
        margin-top: 10px;
    }
}

.conformation-modal{
    .modal-dialog{
        position: absolute;
        top: 45%;
        left: 50%;
        width: 100%;
        transform: translate(-50%,-50%);
        .modal-content{
            border-radius: 10px;
            .modal-header{
                background-color: $navyblue-color;
                .modal-title{
                    font-size: 16px;
                    font-weight: 600;
                    color: $white-color;
                }
                .close{
                    color: $white-color;
                    opacity: 1;
                }
            }
            .modal-body{
                .box-wrapper{
                    padding: 1rem;
                    background-color: $white-color;
                    border-radius: 0.3125rem;
                    position: relative;
                    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
                }
            }
            .modal-footer{
                padding-top: 0;
                padding-bottom: 1rem;
                border-top: 0;
            }
        }
    }
}

.modal-FormatCls{
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    z-index: 5;
    top: 0;
    left: 0;
    .modal-dialog{
        width: 55rem;
        max-width: inherit;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%,-50%);
        .modal-content{
            border-radius: 10px;
            .modal-header{
                background-color: $navyblue-color;
                .modal-title{
                    font-size: 16px;
                    font-weight: 600;
                    color: $white-color;
                }
                .close{
                    color: $white-color;
                    opacity: 1;
                }
            }
            .modal-body{
                overflow: auto;
                max-height: 70vh;
                .Cohorts-link-box{
                    h6{
                        font-size: 14px;
                        color: $black-color;
                        margin-bottom: 2rem;
                        font-weight: 600;
                    }
                    p{
                        font-size: 14px;
                        color: $black-color;
                        margin-bottom: 5px;
                        padding-left: 7px;
                    }
                    .inner-box{
                        font-size: 15px;
                        font-weight: 600;
                        display: flex;
                        width: 100%;
                        padding: 8px 10px;
                        border-radius: 7px;
                        border: 1px solid $gray-light-color;
                        a{
                            word-break: break-all;
                            text-decoration: none;
                            cursor: pointer;
                        }
                        &.success-bg{
                            background-color: $light-green-color;

                        }
                    }
                    .copy-icon-box{
                        height: 32px;
                        width: 32px;
                        background-color: $gray-light-color;
                        border-radius: 5px;
                        margin-left: 1rem;
                        -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.32);
                        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.32);
                    }
                }
                .Sequence-list{
                    margin-left: 2rem;
                }
            }
            .modal-footer{
                padding-top: 0;
                padding-bottom: 1rem;
                border-top: 0;
            }
        }
    }
    &.medium-modal-FormatCls{
        .modal-dialog{
            width: 35rem;
        }
    }
    &.large-modal-FormatCls{
        .modal-dialog{
            width: 90%;
        }
    }
}

.editorFormatCls{
    .p-editor-container{
        .p-editor-toolbar{
            background-color: $gray-light-color;
            border-color: $gray-light-color;
        }
        .p-editor-content{
            border-color: $gray-light-color;
        }
    }
}

.prime-tableformatCls {
    .p-datatable {
        background-color: $white-color;
        margin: 0;
        border-radius: 0;
        .p-datatable-scrollable-wrapper {
            .p-datatable-scrollable-view {
                .p-datatable-scrollable-header {
                    overflow: inherit;
                    background-color: transparent;
                    .p-datatable-scrollable-header-box{
                        .p-datatable-scrollable-header-table{
                            .p-datatable-thead {
                                tr {
                                    th {
                                        background-color: transparent;
                                        border-bottom: 1px solid $gray-color;
                                        font-size: 14px;
                                        color: $black-color;
                                        font-weight: 700;
                                        padding: 0.625rem;
                                        .p-checkbox{
                                            .p-checkbox-box{
                                                border: 2px solid $gray-color;
                                                border-radius: 5px;
                                                &:hover{
                                                    border-color: $gray-color;
                                                }
                                                &:focus{
                                                    border-color: $gray-color;
                                                    box-shadow: none;
                                                }
                                                &.p-highlight{
                                                    background-color: $white-color;
                                                    border-color: $gray-color;
                                                }
                                                .p-checkbox-icon{
                                                    color: $red-color;
                                                    font-size: 10px;
                                                    font-weight: 700;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }

                .p-datatable-scrollable-body {
                    max-height: 350px !important;
                    .p-datatable-scrollable-body-table {
                        border-collapse: collapse;
                        width: 100%;

                        .p-datatable-tbody {
                            tr {
                                background-color: transparent;
                                transition: all 0.4s ease-in-out;
                                &.p-highlight{
                                    background-color: $gray-light-color !important;
                                }
                                // &:hover {
                                //     background-color: $gray-light-color;
                                //     transition: all 0.3s ease-in-out;
                                // }
                                td {
                                    background-color: transparent;
                                    border: 0;
                                    border-bottom: 1px solid $gray-color;
                                    font-size: 14px;
                                    color: $black-color;
                                    padding: 0.625rem;
                                    vertical-align: middle;
                                    .action-icon{
                                        list-style-type: none;
                                        margin-bottom: 0;
                                        li{
                                            padding: 0 5px;
                                            a{
                                                background-color: $gray-light-color;
                                                 border-radius: 5px;
                                                 padding: 14px;
                                              }
                                        }
                                    }
                                    .action-title {
                                        color: $link-color;
                                        font-weight: 700;
                                    }
                                    .p-checkbox{
                                        .p-checkbox-box{
                                            border: 2px solid $gray-color;
                                            border-radius: 5px;
                                            &:hover{
                                                border-color: $gray-color;
                                            }
                                            &:focus{
                                                border-color: $gray-color;
                                                box-shadow: none;
                                            }
                                            &.p-highlight{
                                                background-color: $white-color;
                                                border-color: $gray-color;
                                            }
                                            .p-checkbox-icon{
                                                color: $red-color;
                                                font-size: 10px;
                                                font-weight: 700;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &::-webkit-scrollbar {
                        width: 0.9375rem;
                        border: none;
                        background: transparent;
                    }

                    &::-webkit-scrollbar-button,
                    &::-webkit-scrollbar-track-piece,
                    &::-webkit-scrollbar-corner,
                    &::-webkit-resizer {
                        display: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.375rem;
                        background-color: $navyblue-color;
                        border-left: 0.3125rem solid $white-color;
                        border-right: 0.3125rem solid $white-color;
                        border-top: 0.3125rem solid $white-color;
                        border-bottom: 0.3125rem solid $white-color;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: $white-color;
                        border: 0.375rem solid $white-color;
                        background-clip: padding-box;
                    }
                }
            }
        }
    }
}

.accordion-FormatCls{
    .p-accordion{
        .p-accordion-tab{
            .p-accordion-header{
                &.p-highlight{
                    .p-accordion-header-link{
                        background-color: $gray-color;
                        border-color: $gray-color;
                    }
                }
                .p-accordion-header-link{
                    background-color: $gray-light-color;
                    border-color: $gray-color;
                    &:hover{
                        background-color: $gray-color !important;
                        border-color: $gray-color !important;
                        text-decoration: none;
                    }
                    &:focus{
                        box-shadow: none;
                        outline: none;
                    }
                    .p-accordion-header-text{
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        margin-top: 5px;
                        font-size: 14px;
                        .acc-del-icon{
                            left: auto;
                            background-image: url("../images/delete.png");
                            background-repeat: no-repeat;
                            padding: 7px;
                            display: block;
                            background-position: center;
                            background-size: 15px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
.Dropdown-formatCls{
    margin-bottom: 1rem;
    .p-dropdown{
        padding:2px;
        border-radius: 5px !important;
        overflow: hidden;
        border: 1px solid $gray-color !important;
        width: 100%;
        &.p-focus{
            box-shadow: none !important;
            outline: none;
        }
        .p-dropdown-label{
            font-weight: 500;
            font-size: 0.8125rem;
        }
        .p-dropdown-panel{
            .p-dropdown-items-wrapper{
                .p-dropdown-items{
                    .p-dropdown-item{
                        font-size: 13px;
                        &.p-highlight{
                            background-color: $navyblue-color;
                            color: $white-color;
                        }
                    } 
                }
            }
        }
    }
}
body{
    .p-dropdown-panel{
        .p-dropdown-items-wrapper{
            .p-dropdown-items{
                .p-dropdown-item{
                    font-size: 13px;font-weight: 500;
                    border-bottom: 1px solid $gray-color;
                    &:hover{
                        background-color: $gray-light-color;
                    }
                    &.p-highlight{
                        background-color: $navyblue-color;
                        color: $white-color;
                    }
                } 
            }
            &::-webkit-scrollbar {
                width: 0.9375rem;
                border: none;
                background: transparent;
            }
        
            &::-webkit-scrollbar-button,
            &::-webkit-scrollbar-track-piece,
            &::-webkit-scrollbar-corner,
            &::-webkit-resizer {
                display: none;
            }
        
            &::-webkit-scrollbar-thumb {
                border-radius: 0.375rem;
                background-color: $navyblue-color;
                border-left: 0.3125rem solid $white-color;
                border-right: 0.3125rem solid $white-color;
                border-top: 0.3125rem solid $white-color;
                border-bottom: 0.3125rem solid $white-color;
            }
        
            &::-webkit-scrollbar-track {
                background-color: $white-color;
                border: 0.375rem solid $white-color;
                background-clip: padding-box;
            }
        }
    }
}
.calendar-formatCls{
    .p-calendar {
        width: 100%;
        .p-inputtext {
            border-radius: 7px;
            padding: 9px;
            border: 0.0625rem solid $gray-color;
            width: 100%;
            font-size: 0.8125rem;
            font-weight: 500;
            background-color: $white-color;
            color: $black-color;
            &:focus {
                box-shadow: none;
                border-color: $gray-color !important;
            }
            &.disabled {
                color: $black-color;
                background-color: $gray-light-color;
                border-color: $gray-light-color;
            }
            &:disabled {
                color: $black-color;
                background-color: $gray-light-color;
                border-color: $gray-light-color;
                opacity: unset;
            }
            &:enabled:hover:not(.p-state-error) {
                border: 0.0625rem solid $gray-color;
            }
            &:enabled:focus:not(.p-state-error) {
                border: 0.0625rem solid $gray-color !important;
            }
        }
    }
}
.p-datepicker {
    box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.05);
    border: 0.0625rem solid $gray-color;
    background-color: $white-color;
    .p-datepicker-group-container{
        .p-datepicker-group {
            .p-datepicker-header {
                border-bottom: 1px solid $gray-color;
                padding-bottom: 0.625rem;
                background-color: $white-color;
                .p-datepicker-title {
                    font-weight: 700
                }
                .p-datepicker-prev {
                    color: $navyblue-color;
                    &:hover {
                        color: $navyblue-color;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
                .p-datepicker-next {
                    color: $navyblue-color;
                    &:hover {
                        color: $navyblue-color;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
            .p-datepicker-calendar-container {
                .p-datepicker-calendar {
                    margin-top: 0.3125rem;
                    thead {
                        tr {
                            th {
                                font-size: 0.75rem;
                                color: $black-color;
                                font-weight: 700;
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                font-size: 0.75rem;
                                padding: 0.375rem;
                                span {
                                    border-radius: 50%;
                                    color: $black-color;
                                }
                                &.p-datepicker-today {
                                    .p-highlight {
                                        background-color: $navyblue-color;
                                        color: $white-color
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
}
.DatePicker-formatCls{
    .react-datepicker-wrapper{
        width: 100%;
        .react-datepicker{
            border: 0.0625rem solid $gray-color;
            background-color: $white-color;
            width: 100%;
            box-shadow: none;
            outline: none;
            font-size: 0.8125rem;
            padding: 9px;
            color: $black-color;
            font-weight: 400;
            border-radius: 5px;
            height: auto;
            transition: all 0.3s ease-in-out;
            &::placeholder {
                font-size: 0.8125rem;
                color: $black-color;
                font-weight: 400;
                opacity: 0.5;
            }
        }
    }
}
.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000b3;
    display: flex;
    align-items: center;
    z-index: 99988 !important;
    .loader {
        border: none;
        color: $white-color;
        font-size: 20px;
        margin: 70px auto;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        text-indent: -9999em;
        -webkit-animation: load4 1.3s infinite linear;
        animation: load4 1.3s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
      }
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,-3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }

  .Calendar-info{
    background-color: $light-orange-color !important;
    margin-left: 20px;
    margin-top: 48px;
    box-shadow: none !important;
    .Calendar-title{
        font-size: 22px;
        color: $black-color;
        margin-bottom: 20px;
        font-weight: 600;
    }      
    .info-list{
        p{
            font-size: 12px;
            color: $black-color;
            margin-bottom: 10px;
        }
    }
  }




.error-main{

    width: 100%;
    height: 100vh;
    background-color: $white-color;
    .error-wrapper{
        .icon-box{
            .timeout-icon{
                background-size: 7rem;
                padding: 4rem;
                display: inline-block;
            }
            h2{
                font-size: 150px;
                color: #ff5c3b;
                margin-left: 1rem;
            }
        }
        .error-info-box{
            text-align: center;
            margin-bottom: 3rem;
            h3{
                font-size: 1.875rem;
                color: #ff5c3b;
                margin-bottom: 1rem;
                font-weight: 800;
            }
            p{
                font-size: 1.25rem;
                color: $black-color;
                margin-bottom: 2rem;
            }
            .error-btn{
                padding: 0.5rem 2rem;
                font-size: 1.25rem;
                font-weight: 400;
            }
        }
        .error-footer{
            text-align: center;
            border-top: 0.0625rem solid lightgrey;
            width: 45rem;
            padding-top: 2rem;
            .footer-title{
                font-size: 1rem;
                color: $black-color;
            }
            .action-title{
                color: $black-color;
                padding: 0 0.625rem;
              }
              .title-spacing{
                padding: 0 0.625rem;
              }
        }
    }
}


.support-box{
    padding: 2rem;
        background-color: $white-color;
        border-radius: 35px;
        position: relative;
        -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
        .support-title{
            font-size: 24px;
            font-weight: 700;
            color: $blue-color;
            text-align: center;
            margin-bottom: 2rem;
        }
}
.support-social-info{
    width: 14rem;
    ul{
        list-style-type: none;
        li{
            margin-right: 1rem;
            a{
                cursor: pointer;
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
}
.active-cohort {
    color:$green-color !important;
}
.inactive-cohort{
    color:$red-color !important;
}