@import '../../../assets/scss/color-variables.scss';

.collapse {
    width: 70px !important;
    .rotate{
        transform: rotate(
            180deg
            ) !important;
    }
}
.badge{
    background-color: $white-color;
    color: $black-color;
}