.App {
  // text-align: center;
  &::before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    background-image: url("./assets/images/blue-shapes.png");
    background-repeat: no-repeat;
    padding: 73px 168px;
    display: block;
    background-position: center;
    background-size: 21rem;
    z-index: 0;
  }
  &::after {
    content: "";
    position: fixed;
    right: 0;
    bottom: 0;
    background-image: url("./assets/images/yellow-shapes.png");
    background-repeat: no-repeat;
    padding: 112px 163px;
    display: block;
    background-position: center;
    background-size: 21rem;
    z-index: 0;
  } 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
