@import '../../../assets/scss/color-variables.scss';

.timeError{
    float: right;
    font-size: 10px;
    margin-bottom: 0;
    margin-top: 6px;
    padding-left: 3px;
    color: red;
}

.no-data-info{
    padding: 3rem 1rem;
        background-color: $white-color;
        border-radius: 0.3125rem;
        -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
        font-size: 16px;
        color: $black-color;
        text-align: center;
}
.linktitle{
    font-size: 16px;
    color: $black-color;
    &:hover{
        color: $black-color;
        text-decoration: none;
    }
}
.view-box{
    background-color: $gray-light-color;
    border-radius: 5px;
    padding: 14px;
    display: block;
    height: 32px;
    width: 32px;
}
