.student-title{
    background: #2196F3;
    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: 500;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #2196F3 !important;
    color: white !important;
}
.blue-btn {
    width: 38%;
    padding: 10px;
    margin: 10px;
    background: white;
    font-size: 14px;
    border: 0.0625rem solid #2196F3;
    color: black;
    position: relative;
    z-index: 3;
    overflow: hidden;
    outline: none;
    
  }
  .blue-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2196F3;
    color: white;
    z-index: -1;
    transform: translatex(-100%);
    transition: all 0.4s ease-in-out;
  }
  .blue-btn:hover {
    color: white;
  }
  .blue-btn:hover:before {
    width: 100%;
    transform: translatex(0);
    transition: all 0.4s ease-in-out;
  }
  .blue-btn:focus {
    outline: none;
    box-shadow: none;
  }
  .add-students{
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    font-size: 13px;
  }
  .sub-btns {
    background: #FFBF00 !important;
    border: 1px solid #FFBF00 !important;
  }